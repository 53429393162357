import React from 'react';

function Logo(props) {
    return (
        <div className='logo'>
            <div className='bar'>
            PHIRST
            </div>
        </div>
    );
}

export default Logo;